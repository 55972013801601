import { Link, useNavigate } from 'react-router-dom';
import '../styles/Header.scss'
import useLogout from '../hooks/useLogout';
import DisplayWithAuth from './DisplayWithAuth';
import DisplayWithoutAuth from './DisplayWithoutAuth';
import LogoImage from '../img/race2-logo.png';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';
import { ADMIN_CONSOLE_URL, RAISELY_LINK } from '../constants/externalURLs';
import useConfig from '../hooks/useConfig';

function Header() {

    const navigate = useNavigate();
    const logout = useLogout();
    const {auth} = useAuth();
    const {config} = useConfig();
    
    var totalRaised = 0; // update from database

    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    return(
        <Navbar collapseOnSelect expand="lg" className="navbar-custom" bg="primary">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={LogoImage} className='logo' />
                </Navbar.Brand>
                <Navbar.Brand as={Link} to="/" >
                    Race2 {config?.race_status?.status != 0 ? config?.website_title?.location : ""} {config?.website_title?.year}
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse id="main-navbar" >
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" eventKey={1}>Home</Nav.Link>
                        <Nav.Link as={Link} to="map" eventKey={2}>Map</Nav.Link>
                        <Nav.Link href={RAISELY_LINK} eventKey={9}>Donate</Nav.Link>
                        {/* <Nav.Link as={Link} to="#" eventKey={3}>Our Charities</Nav.Link>
                        <Nav.Link as={Link} to="#" eventKey={4}>Donate</Nav.Link> */}
                        <DisplayWithAuth>
                            <Nav.Link as={Link} to="/my-team" eventKey={5}>Team</Nav.Link>
                            {/* <Nav.Link as={Link} to="/user-settings" eventKey={6}>User Settings</Nav.Link> */}
                            <Nav.Link href={ADMIN_CONSOLE_URL} target="_blank" eventKey={9}>Admin</Nav.Link>
                        </DisplayWithAuth>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <DisplayWithAuth>
                        {/* <Navbar.Text>Signed in as <Nav.Link className="d-inline" as={Link} onClick={signOut} eventKey={7}>{auth?.user?.user_name} (sign out)</Nav.Link></Navbar.Text> */}
                        <NavDropdown title={auth?.user?.user_name} align="end" >
                            <NavDropdown.Item as={Link} to="/user-settings">User Settings</NavDropdown.Item>
                            <NavDropdown.Item as={Link} onClick={signOut}>Sign Out</NavDropdown.Item>
                        </NavDropdown>
                    </DisplayWithAuth>
                    <DisplayWithoutAuth>
                            <Nav.Link as={Link} to="/login" eventKey={8}>Login</Nav.Link>
                    </DisplayWithoutAuth>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Header;