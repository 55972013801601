import { useEffect, useState } from "react";
import "../styles/FormInput.scss"
import { Edit, EyeOpen, EyeSlashed } from "akar-icons";
import { Form, InputGroup } from "react-bootstrap";
import iconSettings from "../constants/iconSettings";

function FormInput(props) {

    const [visible, setVisible] = useState(false);
    const {label, value, disabledValue, errorMessage, onChange, id, pattern, isactive, onClick, haschanged, type, edit, required, text, setValue, ...inputProps} = props;
    const [disabled, setDisabled] = useState(edit);
    var patternSource = null;
    if (pattern) {
        patternSource = pattern.source;
    }
    // assume that inputs are required by default
    var Required = false;
    if (required === undefined) {
        Required = true;
    }
    useEffect(() => {
        if (disabled) {
            setValue(disabledValue)
        }
    }, [disabled])

    return (
        <Form.Group className={props?.nobottommargin ? null : "mb-3"} controlId={props.name}>
            {
                label && type !== 'checkbox'
                    ? <Form.Label>{label}</Form.Label>
                    : null
            }
            <InputGroup hasValidation>
                {
                    type === 'select' ?
                        <Form.Select 
                            {...inputProps}
                            required={Required}
                            value={value}
                            onChange={onChange}
                            disabled={disabled}
                        >
                            {
                                props.selectnullval ?
                                    <option key={''} value={''}>{props.selectnullval}</option>
                                : undefined
                            }
                            {
                                Object.keys(props.values).map((name) => 
                                    <option key={props.values[name]} value={props.values[name]}>{name}</option>
                                )
                            }
                        </Form.Select>
                    :
                        type === 'checkbox' ?
                            <Form.Check
                                {...inputProps}
                                type="checkbox"
                                label={label}
                                checked={value}
                                onChange={onChange}
                            />
                        :
                            <Form.Control 
                                type={
                                    type === "password"
                                        ? visible ? "text" : "password"
                                        : type
                                        // : edit
                                        //     ? "text"
                                        //     : type
                                }
                                {...inputProps} 
                                value={value}
                                // value={
                                //     disabled && disabledValue ? disabledValue : value
                                // }
                                onChange={onChange} 
                                required={Required} 
                                pattern={patternSource}
                                disabled={disabled}
                            />

                }
                {
                    edit
                        ? <InputGroup.Text className="edit-icon form-control-button" onClick={() => {setDisabled(prevVisible => !prevVisible)}}>
                            <Edit strokeWidth={iconSettings.strokeWidth} />
                        </InputGroup.Text>
                        : null
                }
                {
                    type === "password"
                        ? <InputGroup.Text className="visible-icon form-control-button" onClick={() => {setVisible(prevVisible => !prevVisible)}}>
                            {
                                visible
                                    ? <EyeOpen strokeWidth={iconSettings.strokeWidth} />
                                    : <EyeSlashed strokeWidth={iconSettings.strokeWidth} />
                            }
                        </InputGroup.Text>
                        : null
                }
                <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
            </InputGroup>
            {
                text ?
                    <Form.Text>{text}</Form.Text>
                    : null
            }
        </Form.Group>
    )
}

export default FormInput;