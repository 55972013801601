import { Outlet } from "react-router-dom";
import Header from './Header.js'
import Footer from './Footer.js'
import '../styles/Layout.scss';

const Layout = () => {
    return (
        <main className="App">
            <Header />
            <div className="content">
                <Outlet/>
            </div>
            {/* <Footer /> */}
        </main>
    )
}

export default Layout;