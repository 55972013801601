import { useEffect, useMemo, useRef, useState } from "react";
import FormInput from "../FormInput";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { regex } from "../../constants/regex";
import { MapContainer, Marker, TileLayer, Tooltip, useMapEvents } from "react-leaflet";
import '../../styles/Map.scss';
import CurrentLocationMarker from "./CurrentLocationMarker";
import ResultScreen from "../ResultScreen";
import { Alert, Button, Form } from "react-bootstrap";
import LocationMarkerIcon, { locationMarkerAttribution } from "../markerIcons/Location";

const LOG_LOCATION_URL = '/locations/log';
const center = {
    lat: 51.505,
    lng: -0.09
}

function LogLocation({ setDisplay }) {

    const {auth} = useAuth();
    const axiosPrivate = useAxiosPrivate();

    const [errorMessage, setErrorMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [manualLocation, setManualLocation] = useState(false);
    const [values, setValues] = useState({
        post_title: '',
        post_body: '',
        new_transport_mode: false,
        reg_plate: '',
        vehicle_model: '',
        sleep: false,
        awake: false,
        uk_sign_off: false,
        eu_sign_on: false,
        help: false,
        msg_for_safety_team: '',
        latitude: null,
        longitude: null
    });
    const valuesRef = useRef({});
    valuesRef.current = values;
    const inputs = [
        {
            id: 1,
            name: 'post_title',
            type: 'text',
            placeholder: 'Post Title',
            label: 'Post Title',
            pattern: regex.post_title,
            errorMessage: "Title must be between 1 and 50 characters."
        },
        {
            id: 2,
            name: 'post_body',
            type: 'textarea',
            placeholder: 'Type here',
            label: 'Tell us about your journey...',
            pattern: regex.post_body,
            errorMessage: "Post cannot exceed 500 characters.",
            required: false,
            maxLength: 500
        },
        {
            id: 3,
            name: 'new_transport_mode',
            type: 'checkbox',
            label: 'New transport mode',
        },
        {
            id: 4,
            name: 'reg_plate',
            type: 'text',
            placeholder: 'Vehicle Registration',
            label: 'Vehicle Registration',
            pattern: regex.reg_plate,
            required: false,
            errorMessage: "Please enter a valid vehicle registration.",
        },
        {
            id: 5,
            name: 'vehicle_model',
            type: 'text',
            placeholder: 'e.g. Silver Ford Fiesta',
            label: 'Vehicle Model',
            pattern: regex.vehicle_model,
            required: false,
            errorMessage: "Please enter a valid vehicle model.",
        },
        {
            id: 6,
            name: 'sleep',
            type: 'checkbox',
            label: 'Going to sleep',
        },
        {
            id: 7,
            name: 'awake',
            type: 'checkbox',
            label: 'Waking up from sleep',
        },
        {
            id: 8,
            name: 'uk_sign_off',
            type: 'checkbox',
            label: 'Leaving the UK',
        },
        {
            id: 9,
            name: 'eu_sign_on',
            type: 'checkbox',
            label: 'Entering EU mainland',
        },
        {
            id: 10,
            name: 'help',
            type: 'checkbox',
            label: 'I need help (ONLY FOR EMERGENCY)',
        },
        {
            id: 11,
            name: 'msg_for_safety_team',
            type: 'textarea',
            placeholder: 'Type here',
            label: 'Message for safety team',
            pattern: regex.msg_for_safety_team,
            errorMessage: "Message cannot exceed 500 characters.",
            required: false,
            maxLength: 500
        },
    ]

    const setPosition = (position) => {
        setValues({...valuesRef.current, latitude: position.lat, longitude: position.lng });
    }

    const handleSubmit = async (e) => {
        setErrorMessage('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        if (!values.latitude || !values.longitude) {
            setErrorMessage('Please wait for us to detect your location...');
            return;
        }

        // post submission
        try {
            const response = await axiosPrivate.post(
                LOG_LOCATION_URL,
                JSON.stringify(values)
            );
            setDisplay(<ResultScreen
                success={true}
                statusCode={response.status}
                message={`You have successfully logged ${auth.team.team_name}'s location.`}
                navigate={{href: "/", text: "Take me home!"}}
            />);
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            if (err.response?.data?.reasons) {
                setErrorMessage(err.response.data.reasons.join(' '));
            }
            else {
                setErrorMessage(err.response?.data);
            }
        }
    }

    const onChange = (e) => {
        if (typeof values[e.target.name] === 'boolean'){
            setValues({...values, [e.target.name]:!values[e.target.name]});
        }
        else {
            setValues({...values, [e.target.name]:e.target.value})
        }
    }

    useEffect(() => {
        if (!manualLocation) {
            setValues({...values, latitude: null, longitude: null});
        }
        else {
            setValues({...values, latitude: center.lat, longitude: center.lng});
        }
    }, [manualLocation])

    // useEffect(() => {
    //     console.log(values)
    // }, [values])

    return (
        <Form onSubmit={handleSubmit} validated={validated} noValidate>
            <h1>Log Location</h1>
            {inputs.map((input) => (
                <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
            ))}
            <div className="form-map relative">
                {
                    manualLocation ?
                        <ManualMap setposition={setPosition} values={values} />
                    :
                        <GPSMap setposition={setPosition} values={values} />
                }
                <div className="over-map-top-left">
                    {
                        !manualLocation ?
                            <Button variant="primary" onClick={() => {setManualLocation(prev => !prev)}} >
                                Manually set location
                            </Button>
                        : null
                    }
                </div>
            </div>
            <Button variant="primary" type="submit">
                Log Location
            </Button>
            <Alert variant={"danger"} className="error-message mt-3">{errorMessage}</Alert>
        </Form>
    )
}

const GPSMap = ({setposition, values}) => {

    return (
        <MapContainer 
            dragging={false} 
            touchZoom={false} 
            scrollWheelZoom={false} 
            doubleClickZoom={false} 
            boxZoom={false} 
            keyboard={false} 
            zoomControl={false} 
            center={[51.505, -0.09]} 
            zoom={13}
        >
            <TileLayer
                attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | ${locationMarkerAttribution}`}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <CurrentLocationMarker setLocation={setposition} />
        </MapContainer>
    );
}

const ManualMap = ({setposition, values}) => {

    const manualMarkerRef = useRef(null);

    const manualMarkerEventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = manualMarkerRef.current
                if (marker != null) {
                    setposition(marker.getLatLng())
                }
            }
        }),
        []
    )

    return (
        <MapContainer 
            dragging={true} 
            touchZoom={true} 
            scrollWheelZoom={true} 
            doubleClickZoom={true} 
            boxZoom={true} 
            keyboard={true} 
            zoomControl={true} 
            center={[51.510, -0.09]} 
            zoom={13}
        >
            <MapClickHandler setposition={setposition} />
            <TileLayer
                attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | ${locationMarkerAttribution}`}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker 
                position={{lat: values.latitude, lng: values.longitude}} 
                draggable 
                icon={LocationMarkerIcon} 
                ref={manualMarkerRef}
                eventHandlers={manualMarkerEventHandlers}
            >
                <Tooltip direction="top" offset={[0, -40]}>
                    Drag me to your location!
                </Tooltip>
            </Marker>
        </MapContainer>
    );
}

const MapClickHandler = ({setposition}) => {

    const map = useMapEvents({
        click(e) {                                
            setposition(e.latlng);                
        },            
    })
    
    return null;
}

export default LogLocation;