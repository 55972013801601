import { MapContainer, TileLayer, Marker, Polyline, Tooltip, Pane } from "react-leaflet";
import '../styles/Map.scss';
import '../styles/FormInput.scss'
import { useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import axios from "../api/axios";
import FormInput from "./FormInput";
import { Button, Card, FormSelect, Form, Offcanvas, Alert } from "react-bootstrap";
import DisplayWithAuth from "./DisplayWithAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import LocationMarkerIcon, { locationMarkerAttribution } from "./markerIcons/Location";
import HomeMarkerIcon, { homeMarkerAttribution } from "./markerIcons/Home";
import DestinationMarkerIcon, { destinationMarkerAttribution } from "./markerIcons/Destination";
import useConfig from "../hooks/useConfig";

const LATEST_LOCATIONS_URL = '/locations/latest';
const TEAM_LOCATIONS_URL = '/locations/team/';
const GET_TEAMS_URL = '/teams/all';
const POST_COMMENTS_URL = "/locations/comment";
const GET_COMMENTS_URL = "/locations/comments/";

function Map() {

    const {config} = useConfig();
    const axiosPrivate = useAxiosPrivate();

    // get all locations
    const [markers, setMarkers] = useState([]);
    const [polyline, setPolyline] = useState(null);
    const [teamOptions, setTeamOptions] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('latest');
    const [showPostDetails, setShowPostDetails] = useState(false);
    const [selectedPost, setSelectedPost] = useState({});
    const [commentCards, setCommentCards] = useState([]);
    const [commentValidated, setCommentValidated] = useState(false);
    const [commentResultMessages, setCommentResultMessages] = useState({
        success: "",
        error: ""
    });
    const [commentValues, setCommentValues] = useState({
        body: ""
    });
    const [startFinishMarkers, setStartFinishMarkers] = useState([]);

    const commentInputs = [
        {
            id: 1,
            name: "body",
            type: "input",
            as: "textarea",
            placeholder: "Comment on this post...",
            label: null,
            pattern: null,
            errorMessage: "Please input a comment.",
            required: true
        }
    ];

    const createStartFinishMarkers = async () => {
        // get the start/finish locations
        const markers = [];

        if (config?.start?.lat && config?.start?.lng) {
            // create the start marker
            markers.push(
                <Marker 
                    key="start" 
                    position={[config.start.lat, config.start.lng]} 
                    icon={HomeMarkerIcon}
                    pane="priority-markers"
                >
                    <Tooltip direction="top" offset={[0, -40]}>
                        This is the start!
                    </Tooltip>
                </Marker>
            );
        }

        if (config?.finish?.lat && config?.finish?.lng) {
            // create the finish marker
            markers.push(
                <Marker 
                    key="finish" 
                    position={[config.finish.lat, config.finish.lng]} 
                    icon={DestinationMarkerIcon}
                    pane="priority-markers"
                >
                    <Tooltip direction="top" offset={[0, -40]}>
                        This is the finish!
                    </Tooltip>
                </Marker>
            );
        }
        setStartFinishMarkers(markers);
    }

    const getLocations = async () => {
        const URL = selectedTeam === 'latest' ? LATEST_LOCATIONS_URL : TEAM_LOCATIONS_URL + selectedTeam;
        try {
            const response = await axios.get(URL);
            setMarkers(createMarkers(response?.data));
            // join up path
            if (selectedTeam !== 'latest') {
                setPolyline(createPolyline(response?.data));
            }
            else {
                setPolyline(null);
            }
        }
        catch (err) {
            console.log(`Error ${err.response?.status}: ${err.response?.data}`);
        }
    }

    const getTeamOptions = async () => {
        try {
            const response = await axios.get(GET_TEAMS_URL);
            setTeamOptions(createOptions(response?.data));
        }
        catch (err) {
            console.log(`Error ${err.response?.status}: ${err.response?.data}`);
        }
    }

    const parseDate = (time) => {
            const dateOptions = {
                weekday: 'short',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
            }
            const date = new Date(time).toLocaleString('en-GB', dateOptions);
            return date;
    }

    function createMarkers(locations) {
        var markers = [];
        for (const location of locations) {
            if (selectedPost && selectedPost?._id === location._id) {
                // console.log(selectedPost._id, location._id)
                setSelectedPost(location);
            }
            const date = parseDate(location.time_logged);
            markers.push(
                <Marker 
                    key={location._id} 
                    position={[location.latitude, location.longitude]} 
                    icon={LocationMarkerIcon}
                    eventHandlers={{
                        click: () => {
                            setSelectedPost(location);
                            setShowPostDetails(true);
                        }
                    }}
                >
                    <Tooltip direction="top" offset={[0, -40]}>
                        {location.name} arrived here on {date}
                    </Tooltip>
                </Marker>
            )
        }
        return markers;
    }

    function createOptions(teams) {
        var options = [];
        for (const team of teams) {
            options.push(
                <option key={team.id} value={team.id}>{team.name}</option>
            );
        }
        return options;
    }
    
    function createPolyline(locations) {
        let latlngs = locations.map(loc => [loc.latitude, loc.longitude]);
        if (config?.start?.lat && config?.start?.lng) {
            latlngs = [[config.start.lat, config.start.lng]].concat(latlngs);
        }
        return (
            <Polyline color="rgb(255, 189, 48)" positions={latlngs} />
        )
    }

    const getCommentCards = async () => {

        if (!selectedPost?._id) {return}

        try {
            const response = await axios.get(GET_COMMENTS_URL + selectedPost?._id);
            setCommentCards(
                response?.data.toReversed().map(comment => {
                    return (<Card key={comment._id} className="mb-3">
                        <Card.Header><strong>{comment.author_name}</strong> at <strong>{parseDate(comment.created_at)}</strong></Card.Header>
                        <Card.Body>{comment.body}</Card.Body>
                    </Card>)
                })
            );
        }
        catch (err) {
            console.log(`Error ${err.response?.status}: ${err.response?.data}`);
        }

    }

    const handleCommentSubmit = async (e) => {
        setCommentResultMessages();
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setCommentValidated(true);
            return;
        }

        // post submission
        try {
            const response = await axiosPrivate.post(
                POST_COMMENTS_URL,
                JSON.stringify({
                    id: selectedPost._id,
                    ...commentValues
                })
            );
            setCommentResultMessages({success: "Comment Posted!"});
            getLocations().then(getCommentCards);
            setCommentValues({body:""});
        }
        catch (err) {
            console.log(`Error ${err?.response?.status}: ${err?.response?.data}`);
            if (err.response?.data?.reasons) {
                setCommentResultMessages({error: err?.response?.data?.reasons?.join(' ')});
            }
            else {
                setCommentResultMessages({error: err?.response?.data});
            }
        }
    }

    // on config load
    useEffect(() => {

        // only continue if status is 'awaiting start' or 'ongoing' or 'finished' ...
        if (config?.race_status?.status > 0) {
            createStartFinishMarkers();
        }
    }, [config])

    useEffect(() => {
        if (!config) return;

        if (config?.race_status?.status >= 2) {
            getLocations();
            getTeamOptions();
        }
    }, [config])

    useEffect(() => {
        if (config?.race_status?.status >= 2) {
            getLocations();
        }
    }, [selectedTeam])

    useEffect(() => {
        getCommentCards();
    }, [selectedPost])

    const onChangeComment = (e) => {
        setCommentValues({...commentValues, [e.target.name]:e.target.value})
    }

    const handleCanvasHide = () => {
        setShowPostDetails(false);
        setCommentResultMessages({});
        // setCommentValues({});
        setCommentValidated(false);
        // setCommentCards([]);
    }

    return (
        <>
            <div id="map">
                <MapContainer center={[50, 10]} zoom={4.5} scrollWheelZoom={true}>
                    <TileLayer
                        attribution={`&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | ${locationMarkerAttribution} | ${homeMarkerAttribution} | ${destinationMarkerAttribution}`}
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Pane name="priority-markers" style={{zIndex: 600}}>
                        {startFinishMarkers}
                    </Pane>
                    {markers}
                    {polyline}
                </MapContainer>
                {
                    config?.race_status?.status >= 2 ?
                        <div className="teams-dropdown">
                            <FormSelect value={selectedTeam} onChange={e => {setSelectedTeam(e.target.value)}}>
                                <option value="latest">Latest</option>
                                {teamOptions}
                            </FormSelect>
                        </div>
                    : undefined
                }
            </div>
            <Offcanvas show={showPostDetails} onHide={handleCanvasHide} placement="end" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        {
                            selectedPost.approved ? selectedPost.post_title : "Unapproved post"
                        }
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Card className="mb-3">
                        <Card.Header><strong>{selectedPost.name}</strong> logged at <strong>{parseDate(selectedPost.time_logged)}</strong></Card.Header>
                        {
                            selectedPost.approved
                                ? <Card.Body>{selectedPost.post_body}</Card.Body>
                                : undefined
                        }
                    </Card>
                    {
                        selectedPost.approved
                            ?
                                <Card className="mb-3">
                                    <Card.Header>
                                        Comments
                                    </Card.Header>
                                    <DisplayWithAuth>
                                        <Card.Header>
                                            <Form onSubmit={handleCommentSubmit} validated={commentValidated} noValidate>
                                                {commentInputs.map((input) => (
                                                    <FormInput key={input.id} {...input} value={commentValues[input.name]} onChange={onChangeComment} nobottommargin="true" />
                                                ))}
                                                {
                                                    commentValues.body
                                                    ?
                                                        <Button type="submit" variant="primary" className="mt-3">
                                                            Post Comment
                                                        </Button>
                                                    : null
                                                }
                                                <Alert variant="danger" className="error-message mt-3">{commentResultMessages?.error}</Alert>
                                                <Alert variant="success" className="success-message mt-3">{commentResultMessages?.success}</Alert>
                                            </Form>
                                        </Card.Header>
                                    </DisplayWithAuth>
                                    <Card.Body>
                                        {commentCards}
                                    </Card.Body>
                                </Card>
                            : undefined
                    }
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Map;