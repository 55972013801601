import { useEffect, useState } from "react";
import FormInput from "./FormInput";
import { Link, useNavigate } from "react-router-dom";
import axios from "../api/axios";
import { regex } from "../constants/regex";
import { Alert, Col, Container, Form, Row, Button } from "react-bootstrap";
import Gender from "../classes/Gender";
import escapeRegExp from "../functions/escapeRegExp";

const REGISTER_URL = '/users/register'

function Register() {
    const navigate = useNavigate();

    const [errorMessage, setErrorMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({
        full_name: '',
        user_name: '',
        gender: undefined,
        dob: undefined,
        email: '',
        password: '',
        passwordConf: '',
        phone_number: ''
    })
    const inputs = [
        {
            id: 1,
            name: 'full_name',
            type: 'text',
            placeholder: 'Full Name',
            label: 'Full Name',
            pattern: regex.full_name,
            errorMessage: "Only alphabetic characters, '-' and '.'"
        },
        {
            id: 2,
            name: 'user_name',
            type: 'text',
            placeholder: 'Username',
            label: 'Username',
            pattern: regex.user_name,
            errorMessage: "Between 4 and 16 alphanumeric characters, '_' and '-'"
        },
        {
            id: 3,
            name: 'gender',
            type: 'select',
            label: 'Gender',
            errorMessage: 'Please select a gender',
            values: Gender.values,
            selectnullval: 'Select Gender'
        },
        {
            id: 4,
            name: 'dob',
            type: 'date',
            label: 'Date of Birth',
            errorMessage: 'Please enter a valid date of birth'
        },
        {
            id: 5,
            name: 'email',
            type: 'email',
            placeholder: 'Email',
            label: 'Email',
            pattern: regex.email_standrews,
            errorMessage: 'Please enter a valid @st-andrews.ac.uk email address'
        },
        {
            id: 6,
            name: 'password',
            type: 'password',
            placeholder: 'Password',
            label: 'Password',
            pattern: regex.password,
            errorMessage: "At least 8 characters, with an uppercase, lowercase, digit, and special character (#?!@$%^&*-)"
        },
        {
            id: 7,
            name: 'passwordConf',
            type: 'password',
            placeholder: 'Confirm Password',
            label: null,
            pattern: new RegExp(escapeRegExp(values.password)),
            errorMessage: 'Must match the password field'
        },
        {
            id: 8,
            name: 'phone_number',
            type: 'tel',
            placeholder: 'Telephone',
            label: 'Telephone',
            pattern: regex.phone_number,
            text: 'We need this to keep in contact with you during the race.'
        }
    ]

    const handleSubmit = async (e) => {
        setErrorMessage('');
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        // post submission
        try {
            const response = await axios.post(
                REGISTER_URL,
                JSON.stringify(values),
                {
                    headers: {'Content-Type': 'application/json'}
                }
            );
            console.log(JSON.stringify(response?.data));
            navigate('/login');
        }
        catch (err) {
            console.log(`Error ${err.response.status}: ${err.response?.data}`);
            if (err.response?.data?.reasons) {
                setErrorMessage(err.response.data.reasons.join(' '));
            }
            else {
                setErrorMessage(err.response?.data);
            }
        }
    }

    const onChange = (e) => {
        setValues({...values, [e.target.name]:e.target.value})
    }

    return (
        <Container className="register-container widgets-container">
            <Row>
                <Col></Col>
                <Col md="auto" className="register-col widget">
                    <Form onSubmit={handleSubmit} validated={validated} noValidate>
                        <h1>Register</h1>
                        {inputs.map((input) => (
                            <FormInput key={input.id} {...input} value={values[input.name]} onChange={onChange}/>
                        ))}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                        <Alert variant={"danger"} className="error-message mt-3">{errorMessage}</Alert>
                        <Link to="/login">Already registered? Login</Link>
                    </Form> 
                </Col>
                <Col></Col>
            </Row>
        </Container>
    )
}

export default Register;